import React, { useState, useRef, useEffect } from "react";
import appointmentService from "../../utils/api/v1/appointmentService";
import labService from "../../utils/api/v1/labService";
import packService from "../../utils/api/v1/packService";
import moment from "moment";

//typings
import { SheetError } from "../../utils/interfaces/Flatfile";
import { PackAndService } from "../../utils/interfaces/Pack";
import SelectedService from "../../utils/interfaces/LabServices";
import alliancesService from "../../utils/api/v1/alliancesService";

//components
import PrivateRoute from "../../components/Authentication/PrivateRoute";
import LoadingError from "../../components/Loaders/LoadingError";
import AppointmentData from "../../components/Appointments/Offline/AppointmentData";
import NurseAndLabServices from "../../components/Appointments/Offline/NurseAndLabServices";
import DiscountCodeApplier from "../../components/Appointments/Offline/DiscountCodeApplier";
import SalesSourceSelector from "../../components/Appointments/Offline/SalesSourceSelector";
import UserData from "../../components/Appointments/UserData";
import Swal from "sweetalert2";
import { User } from "../../utils/interfaces/User";
import MassiveUserDataMx from "../../components/Appointments/MassiveUserDataLoaderMx";

// styles
import Flex from "../../components/Containers/Flex";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from '@material-ui/core/TextField';
import { Container, Errors } from "../../assets/styles/Offliners";

// hooks
import { useScrollTop } from "../../hooks";
import { IDoctorData, IDoctorPayload } from "../../utils/interfaces/Funnel";
import { doctorAPI } from "../../utils/api/v2";
import { fetchHelper } from "../../utils/api/v1/fetchHelper";

const OfflineAppointmentMxCreate = (): JSX.Element => {
  const [error, setError] = useState<any>({});
  const [validationErrors, setValidationErrors] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [additionalCharge, setAdditionalCharge] = useState<string>("");
  const [isMassiveUpload, setIsMassiveUpload] = useState<boolean>(false);
  const [isOperative, setIsOperative] = useState<boolean>(false);
  const [massiveUserData, setMassiveUserData] = useState<User[]>([]);
  const [sheetErrors, setSheetErrors] = useState<SheetError[]>([]);
  const [allLabServices, setAllLabServices] = useState<PackAndService[]>(
    []
  );
  const [allDoctors, setAllDoctors] = useState<IDoctorPayload[]>([]);
  const [salesSource, setSalesSource] = useState<string>("marketplace");
  const [origins, setOrigins] = useState<string[]>([]);
  const [selectedOrigin, setSelectedOrigin] = useState<string>("no");
  const [inputOrigin, setInputOrigin] = useState<string>("");
  const [originLoading, setOriginLoading] = useState<boolean>(false);
  const appointmentDataRef = useRef<any>(null);
  const nurseAndLabServicesRef = useRef<any>(null);
  const userDataRef = useRef<any>(null);
  const discountCodeRef = useRef<any>(null);
  const doctorRef = useRef<Array<IDoctorData | null>>([null]);

  const getUserServicesOrPacks = (
    userDataState: any,
    packs: boolean = false
  ) => {
    const userServices: PackAndService[][] = userDataState.userServices;
    return userServices?.map((eachUser: PackAndService[]) => {
      // for each user, we only want their services or their packs
      return eachUser.filter((service: PackAndService) => {
        // filter everything we don't need
        return !!service.isPack === packs;
      });
    });
  };

  const handleSubmit = async () => {
    // check validations
    let vErrors: string[] = [];
    vErrors = vErrors.concat(appointmentDataRef.current.validate());
    vErrors = vErrors.concat(nurseAndLabServicesRef.current.validate());
    vErrors = vErrors.concat(userDataRef.current.validate());

    if (doctorRef.current.some((doctor: IDoctorData | null) => {
      if (!doctor) return false;
      return !doctor.id && !doctor.name;
    })) {
      vErrors.push("Cada doctor debe tener ID o nombre");
    }

    if (selectedOrigin === "Other" && inputOrigin === "") {
      vErrors.push("Debes incluir un origen o seleccionar '-'");
    }

    if (vErrors.length > 0) {
      setValidationErrors(vErrors);
      return;
    } else {
      setValidationErrors([]);
    }

    useScrollTop();

    const appointmentData = appointmentDataRef.current.getState();
    const nurseAndLabServices = nurseAndLabServicesRef.current.getState();
    const userDataState = userDataRef.current.getState();
    const userData = isMassiveUpload
      ? userDataState
      : JSON.parse(JSON.stringify(userDataState.userData));
    const doctorData = isMassiveUpload
      ? Array(userData.length).fill(doctorRef.current[0])
      : doctorRef.current.map((doctor: IDoctorData | null) => {
        if (!doctor) return null;
        if (!doctor.id) {
          return {
            ...doctor,
            id: doctor.name,
          }
        }
        return doctor;
      });
    const discountData = discountCodeRef.current.getState();
    const allServices = nurseAndLabServices.selectedItems.map(
      (service: SelectedService) => ({
        id: service.id,
        amount: 1,
        fonasa: false,
      })
    );

    const userServices = isMassiveUpload
      ? userData.map(() => allServices)
      : getUserServicesOrPacks(userDataState);
    const userPacks = getUserServicesOrPacks(userDataState, true);
    const labServices = [].concat.apply([], userServices);
    const packs = [].concat.apply([], userPacks);
    // fix user data date of birth format
    for (let i = 0; i < userData.length; i++) {
      let formatted = moment(userData[i].date_of_birth, "YYYY-MM-DD").format(
        "YYYY-MM-DDT12:00:00+00"
      );
      userData[i].date_of_birth = formatted;
    }

    // check if any patient has no service attached
    // if not massive, check whether there is no user services, any is empty, or lengths are mismatched
    if (!isMassiveUpload) {
      const allUserServices: PackAndService[][] = userDataState.userServices;
      if (
        !allUserServices.length ||
        userData.length !== allUserServices.length ||
        allUserServices.some((user: PackAndService[]) => !user.length)
      ) {
        vErrors = vErrors.concat([
          "Debe agendar exámenes para todos los pacientes",
        ]);
      }
    }

    if (vErrors.length > 0) {
      setValidationErrors(vErrors);
      useScrollTop();
      return;
    } else {
      setValidationErrors([]);
    }

    setLoading(true);
    try {
      const [initTimeblock, finishTimeblock] =
        appointmentData.selectedTimeblock.split("-");
      const formatedDate = appointmentData.date.format("YYYY-MM-DD");
      let finalOrigin: string | null = null;
      if (selectedOrigin !== "no") {
        finalOrigin = selectedOrigin !== "Other" ? selectedOrigin : inputOrigin;
      }
      const newAppointment = {
        user_data: userData,
        user_services: userServices,
        user_packs: userPacks,
        doctors: doctorData,
        appointment_data: {
          is_operative: appointmentData.isOperative,
          managing_email: appointmentData.managingEmail,
          target_address: `${appointmentData.address.address}, ${appointmentData.address.comuna}, ${appointmentData.address.region}`,
          status: appointmentData.isFinished ? "finished" : "confirmed",
          contact_channel: appointmentData.selectedContactChannel
            ? (appointmentData.selectedContactChannel as string).toLowerCase()
            : undefined,
          target_residence_type: appointmentData.isApartment
            ? "apartment"
            : "house",
          begin_date: `${formatedDate} ${initTimeblock}`,
          end_date: `${formatedDate} ${finishTimeblock}`,
          comuna: appointmentData.address.comuna,
          region: appointmentData.address.region,
          country: appointmentData.address.country,
          address_line_1: appointmentData.address.address,
          is_factura: appointmentData.isFactura,
          finished: appointmentData.isFinished,
          operative_data: appointmentData.operativeData,
          referral_code: discountData.discountCodeValidated
            ? discountData.discountCode
            : null,
          sales_source: salesSource,
          client_origin: finalOrigin,
        },
        offline_payment: appointmentData.isOfflinePayment,
        folio: appointmentData.folio,
        nurses: nurseAndLabServices.selectedNurses,
        lab_services: labServices.reduce((serviceAccumulator, service) => {
          for (let i = 0; i < service.amount; i++) {
            serviceAccumulator.push(service.id);
          }
          return serviceAccumulator;
        }, []),
        // does not do much unless we allow more than one pack in total
        packs: packs.reduce((packAccumulator, pack) => {
          for (let i = 0; i < pack.amount; i++) {
            packAccumulator.push(pack.id);
          }
          return packAccumulator;
        }, []),
        additional_charge: +additionalCharge,
        is_massive_upload: isMassiveUpload,
      };

      if (appointmentData.isApartment) {
        // @ts-ignore
        newAppointment.appointment_data.target_apartment_number =
          appointmentData.aptNumber;
      }
      const req = await appointmentService.createOffliner(newAppointment);
      await Swal.fire({
        title: "¡Cita creada!",
        text: "La cita se ha creado correctamente",
        icon: "success",
      });
    } catch (err) {
      setError(err);
      console.log(err);
      await Swal.fire({
        title: "Whoops, something went wrong!",
        text: err.message,
        icon: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchClientOrigins = async () => {
      await fetchHelper(setOriginLoading, async () => {
        const res = await alliancesService.fetchClientOrigins(salesSource);
        setOrigins(res.data.origins);
        setSelectedOrigin("no");
        setInputOrigin("");
      })
    }

    fetchClientOrigins();
  }, [salesSource])

  useEffect(() => {
    const fetchLabServices = async () => {
      const res = await labService.fetchOrderedServices("mx");
      const packs = await packService.fetchPacks({});
      const doctors = await doctorAPI.list("mx");
      const filteredPacks = packs.data.filter((pack) => {
        return pack.active && pack.country === "Mexico";
      });
      const validLabServices = res.data.data.map((service) => {
        return {
          ...service,
          isPack: false,
        };
      });
      const validPacks = filteredPacks.map((pack) => {
        return {
          ...pack,
          isPack: true,
          amount: 1,
        };
      });
      setAllLabServices([...validLabServices, ...validPacks]);
      setAllDoctors(doctors.data);
    };
    fetchLabServices();
  }, []);

  return (
    <PrivateRoute>
      <Container variant="outlined">
        <LoadingError loading={loading} error={error} />
        <Flex justify="center" margin="1rem 0rem 3rem 0rem">
          <Flex direction="column" justify="center">
            <h1>
              Crear <i>offline appointment</i>
            </h1>
            <br />
            <Grid container direction="row" alignItems="center">
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography>Carga simple</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "center" }}>
                <Switch
                  color="primary"
                  value={isMassiveUpload}
                  onChange={(e) =>
                    setIsMassiveUpload(e.target.checked as boolean)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <Typography>Carga masiva</Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography>Agenda Normal</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "center" }}>
                <Switch
                  color="primary"
                  checked={isOperative}
                  onChange={(e) => setIsOperative(e.target.checked as boolean)}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography>Operativo</Typography>
              </Grid>
            </Grid>
          </Flex>
        </Flex>
        {validationErrors.length > 0 && (
          <Errors>
            <ul>
              {validationErrors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </Errors>
        )}
        <AppointmentData
          askPaymentMethod
          ref={appointmentDataRef}
          setError={(err) => {
            setError(err);
          }}
          country="México"
          isOperative={isOperative}
        />
        <NurseAndLabServices
          ref={nurseAndLabServicesRef}
          setError={(err) => {
            setError(err);
          }}
          isMassiveUpload={isMassiveUpload}
          allowMultipleNurses={isOperative}
          allLabServices={allLabServices}
          locale="mx"
          doctorRef={doctorRef}
          allDoctors={allDoctors}
        />
        {isMassiveUpload && (
          <MassiveUserDataMx
            ref={userDataRef}
            setLoading={setLoading}
            setError={(err) => setError(err)}
            setSheetErrors={setSheetErrors}
            sheetErrors={sheetErrors}
            setMassiveUserData={setMassiveUserData}
            massiveUserData={massiveUserData}
          />
        )}
        {!isMassiveUpload && (
          <UserData
            ref={userDataRef}
            setError={(err) => {
              setError(err);
            }}
            locale="mx"
            allLabServices={allLabServices}
            doctorRef={doctorRef}
            allDoctors={allDoctors}
          />
        )}
        <Flex justify="center" gap="1rem">
          <DiscountCodeApplier
            isMassiveUpload={isMassiveUpload}
            nurseAndLabServicesRef={nurseAndLabServicesRef}
            usersRef={userDataRef}
            ref={discountCodeRef}
          />
          <SalesSourceSelector
            setValue={setSalesSource}
            currentValue={salesSource}
          />
          <Flex direction="column" justify="flex-end" margin="0 0 2.5rem 0">
            <InputLabel id="changeSalesSource">Client Origin</InputLabel>
            <Select
              labelId="change-sales-source"
              id="change-sales-soruce"
              label="Sales Source"
              value={selectedOrigin}
              onChange={(e) => {
                setSelectedOrigin(e.target.value as string)
              }}
            >
              {origins.map((origin) => {
                return (
                  <MenuItem key={origin} value={origin}>
                    {origin}
                  </MenuItem>
                );
              })}
              <MenuItem key={"Other"} value={"Other"}>
                Otro
              </MenuItem>
              <MenuItem key={"no"} value={"no"}>
                -
              </MenuItem>
            </Select>
          </Flex>
          {selectedOrigin === "Other" && (
            <Flex direction="column" justify="flex-end" margin="0 0 2.5rem 0">
              <TextField value={inputOrigin} onChange={(e) => {setInputOrigin(e.target.value as string)}} placeholder="Origen"/>
            </Flex>
          )}
        </Flex>
        <Flex justify="right" align="center" margin="1rem 0rem">
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={handleSubmit}
            disabled={loading}
          >
            Crear
          </Button>
        </Flex>
      </Container>
    </PrivateRoute>
  );
};

export default OfflineAppointmentMxCreate;
